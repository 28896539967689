import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { history, store } from './store';

import App from './components/App';
import { ConnectedRouter } from 'react-router-redux';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';

ReactDOM.render((
  <Provider store={store}>
    <ConnectedRouter history={history}>
    <Router>
      <Switch>
        <Route path="/" component={App} />
      </Switch>
    </Router>
    </ConnectedRouter>
  </Provider>

), document.getElementById('root'));
