// import { APP_LOAD, CARS_LOADED, REDIRECT } from '../constants/actionTypes';
import React, { Suspense } from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { SnackbarProvider, withSnackbar } from 'notistack';

// import TowerDetails from '../components/TowerDetails';
// import Home from '../components/Home';
// import PropTypes from 'prop-types';
// import agent from '../agent';
// import { connect } from 'react-redux';
// import { push } from 'react-router-redux';
// import socketAgent from '../socketAgent';
// import { store } from '../store';

const GenericNotFound = React.lazy(() => import('../components/Templates/GenericNotFound'));
const Header = React.lazy(() => import('./Templates/Header'));
const Landing = React.lazy(() => import('../components/Landing'));
// const PublicTemplate = React.lazy(() => import('../components/Templates/PublicTemplate'));


// const mapStateToProps = state => {
//   return {
//     appLoaded: state.common.appLoaded,
//     appName: state.common.appName,
//     currentUser: state.common.currentUser,
//     redirectTo: state.common.redirectTo
//   }};

// const mapDispatchToProps = dispatch => ({
//   setCars: payload =>
//     dispatch({ type: CARS_LOADED, payload }),
//   onLoad: (payload, token) =>
//     dispatch({ type: APP_LOAD, payload, token, skipTracking: true }),
//   onRedirect: () =>
//     dispatch({ type: REDIRECT })
// });
 
const App = (props) =>{
  const { currentUser } = props;
  const appName = 'HypedRush';
  return (
    <Suspense fallback={<div/>}>
      <Header
        appName={appName}
        currentUser={currentUser} 
      />
      <Router>
        <Switch>
          <Route exact path="/" component={Landing}/>
          <Route path="/404" component={GenericNotFound} />
          <Redirect to="/404" />
        </Switch>
      </Router>
    </Suspense>
  );
}
// class App extends React.Component {
//   constructor(props) {
//     super(props);

//     // socketAgent
//     //   .connect()
//     //   .then(() => {
//     //     socketAgent.attachMessageHandler(this.messageHandler.bind(this));
//     //     socketAgent.sendJson({ command: 'listCars' });
//     //   })
//     //   .catch(err => {
//     //     this.props.enqueueSnackbar('Could not connect to server!', { variant: 'error' });
//     //   });

//     // const token = window.localStorage.getItem('jwt');
//     // if (token) {
//     //   agent.setToken(token);
//     // }

//     this.props.onLoad(null);
//     // this.props.onLoad(token ? agent.Auth.current() : null, token);
//   }
//   // componentWillReceiveProps(nextProps) {
//   //   if (nextProps.redirectTo) {
//   //     store.dispatch(push(nextProps.redirectTo));
//   //     this.props.onRedirect();
//   //   }
//   // }

//   messageHandler(message) {
//     try {
//       const json = JSON.parse(message.data);
//       try {
//         switch (json.type) {
//           case 'listCarsRes':
//             this.props.setCars({
//               cars: json.cars,
//               carsCount: json.carsCount
//             })
//             break;
//           default:
//         }
//       } catch (err) {
//         console.error(err);
//       }
//     } catch (e) {
//       console.log('Invalid JSON: ', message.data);
//       return;
//     }
//   }

//   render() {
//     if (this.props.appLoaded) {
//       return (
//         <Suspense fallback={<div/>}>
//           <Header
//             appName={this.props.appName}
//             currentUser={this.props.currentUser} 
//           />
//           <Router>
//             <Switch>
//               <Route exact path="/" component={Landing}/>
//               {/* <Route exact path="/" component={Home}/>
//               <Route path="/tower/:id" component={TowerDetails}/> */}
//               <Route path="/404" component={GenericNotFound} />
//               <Redirect to="/404" />
//             </Switch>
//           </Router>
//         </Suspense>
//       );
//     }
//     return (
//       <Suspense fallback={<div/>}>
//         <Header
//           appName={this.props.appName}
//           currentUser={this.props.currentUser} />
//         <PublicTemplate>
//           <div></div>
//         </PublicTemplate>
//       </Suspense>
//     );
//   }
// }

// App.propTypes = {
//   enqueueSnackbar: PropTypes.func.isRequired,
// };

const MyApp = withSnackbar(App);
const IntegrationNotistack = (props) => {
  return (
    <SnackbarProvider maxSnack={3}>
      <MyApp 
        { ...props }
      />
    </SnackbarProvider>
  );
}

export default IntegrationNotistack;// connect(mapStateToProps, mapDispatchToProps)(IntegrationNotistack);
